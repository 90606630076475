$(document).ready(function(){
    $("#span").click(function(){
        $(".menu").toggleClass("open");
    });
    if($(window).scrollTop()>50){
      $('.global-nav').addClass('scrolled-nav');
    }
    var scrollTop = 0;
    $(window).scroll(function(){
      scrollTop = $(window).scrollTop();
       $('.counter').html(scrollTop);
      
      if (scrollTop >= 100) {
        $('.global-nav').addClass('scrolled-nav');
      } else if (scrollTop < 100) {
        $('.global-nav').removeClass('scrolled-nav');
      } 
      
    }); 

    
});